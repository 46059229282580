$blue        : #128ebc;
$blue-bright : #4bb2d8;
$blue-light  : #6f98a2;
$blue-gray   : #98a4a4;
$gray        : #eeeeee;
$green       : #9acfb0;
$green-bright: #368f71;
$green-light : #EBF6F0;
$red         : #9B2335;
$teal        : #164F63;
$teal-bright : #0e4e63;
$teal-dark   : #3c4f55;
$white       : #ffffff;
$yellow      : #f9f6e0;