.awards-row {
    .columns {
        .awards-wrapper {
            display: flex;
            flex-flow: column nowrap;
            border: 0px solid #707070;
            border-top-width: 1px;
            margin-top: 33px;
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
