.promo-banner {
    padding: 57.5px 114px 34.5px ;
    @media screen and (max-width: 1024px) {
        padding: 0px;
        
    }
    .row {
        display: flex;
        .columns {
            width:99.5%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .columns:first-child {
            padding: 0px;
            img {
                &.desktop-banner {
                    display: block;
                    @media screen and (max-width: 425px) {
                        display: none;
                    }
                }
                &.mobile-banner {
                    display: none;

                    @media screen and (max-width: 425px) {
                        display: block;
                    }
                }
            }
        }
        .columns:last-child {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            .copy-wrapper {

            }
            .brand-wrapper {

            }
        }
    }
}