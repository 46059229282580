/*
Theme Name: Specialists in General Surgery
Theme URI: http://bravenewmedia.net
Description: This is a custom horizontally repeating theme design.
Author: Brave New Media
Version: 1.0
License: GNU General Public License
License URI: license.txt

---------- CSS 3 Styles -----------

BNM Colors
Dark Purple: #201C23
Light Purple: #433B48
Pink: #D9544E
Tan: #D3C7B1

-webkit-border-radius: 8px;
-moz-border-radius: 	 8px;
border-radius: 				 8px;
-webikit-box-sizing: border-box;
-moz-box-sizing: 		 border-box;
box-sizing: 				 border-box;
-webkit-box-shadow: #666 0px 2px 3px;
-moz-box-shadow: 		#666 0px 2px 3px;
box-shadow: 				#666 0px 2px 3px;
background: #EEFF99;
background: -webkit-gradient(linear, 0 0, 0 bottom, from(#EEFF99), to(#66EE33));
background: -webkit-linear-gradient(#EEFF99, #66EE33);
background: -moz-linear-gradient(#EEFF99, #66EE33);
background: -ms-linear-gradient(#EEFF99, #66EE33);
background: -o-linear-gradient(#EEFF99, #66EE33);
background: linear-gradient(#EEFF99, #66EE33);
-pie-background: linear-gradient(#EEFF99, #66EE33);
behavior: url(/PIE.htc);
*/
.promo-banner {
  padding: 57.5px 114px 34.5px;
}
@media screen and (max-width: 1024px) {
  .promo-banner {
    padding: 0px;
  }
}
.promo-banner .row {
  display: flex;
}
.promo-banner .row .columns {
  width: 99.5%;
}
@media screen and (max-width: 768px) {
  .promo-banner .row .columns {
    width: 100%;
  }
}
.promo-banner .row .columns:first-child {
  padding: 0px;
}
.promo-banner .row .columns:first-child img.desktop-banner {
  display: block;
}
@media screen and (max-width: 425px) {
  .promo-banner .row .columns:first-child img.desktop-banner {
    display: none;
  }
}
.promo-banner .row .columns:first-child img.mobile-banner {
  display: none;
}
@media screen and (max-width: 425px) {
  .promo-banner .row .columns:first-child img.mobile-banner {
    display: block;
  }
}
.promo-banner .row .columns:last-child {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.awards-row .columns .awards-wrapper {
  display: flex;
  flex-flow: column nowrap;
  border: 0px solid #707070;
  border-top-width: 1px;
  margin-top: 33px;
}
.awards-row .columns .awards-wrapper img {
  margin-left: auto;
  margin-right: auto;
}

/************ Universal Styles ************/
.staffcontent.regiondarkblue a:hover, .staffcontent.regiondarkblue a:focus {
  color: #ffffff;
}

a.button,
.blog .readmore a {
  background: transparent;
  border: 1px solid #3c4f55;
  border-box: box-sizing;
  color: #3c4f55;
  font-family: "source-sans-pro", helvetica, arial, sans-serif;
  font-size: 0.875rem;
  max-width: 100%;
  padding: 0.4rem 1rem 0.3rem;
  text-transform: uppercase;
}

.home .medium-3 a.button {
  font-size: 12px;
}

.patient-resources section a.button {
  margin-bottom: 0;
  margin-top: 20px;
}

a.button:hover,
a.button:focus {
  background: transparent;
  border: 1px solid #6f828a;
  color: #6f828a;
}

a.button.tall span {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

a.button.white {
  border: 1px solid #ffffff;
  color: #ffffff;
}

a.button.white:hover,
a.button.white:focus {
  border: 1px solid #e3e3e3;
  color: #e3e3e3;
}

a.button.yellow {
  border: 1px solid #f9f6e0;
  color: #f9f6e0;
}

a.button.blue,
.blog .readmore a {
  border: 1px solid #128ebc;
  color: #128ebc;
}

a.button.blue:hover,
a.button.blue:focus {
  border: 1px solid #106e91;
  color: #106e91;
}

a.button.bluesolid {
  background: #128ebc;
  border: none;
  color: #ffffff;
}

a.button.left {
  margin-right: 25px;
}

.aligncenter {
  display: block;
  margin: 0 auto 15px;
}

.alignleft {
  clear: both;
  margin: 0 auto 15px;
  display: block;
}

.faq section .alignleft, .covid-19-faq section .alignleft {
  clear: none;
  float: left;
  margin-right: 10px;
}

.alignright {
  clear: both;
  margin: 0 auto 15px;
  display: block;
}

.altgreysection article {
  padding: 15px 0;
}

.altgreysection article:nth-child(2n+1) {
  background: #eeeeee;
  margin-bottom: 15px;
  padding: 30px 0 10px;
}

.aside.regiongrey {
  padding: 20px 30px;
  margin-top: 40px;
}

.aside h3 {
  margin-bottom: 10px;
}

.aside p,
.aside ul li,
.aside ul li a {
  color: #6f98a2;
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.aside ul {
  margin-bottom: 20px;
}

.aside section {
  margin-bottom: 40px;
}

.bg-blue {
  background-color: #004758;
}

body {
  background: #ffffff;
  font-family: "source-sans-pro", helvetica, arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  position: static;
}

.boxed {
  background: #ffffff;
  border-radius: 1px;
  color: #6f98a2;
  padding: 10px 20px;
}

.single-procedure .regiongreyblue .boxed p {
  color: #6f98a2;
  margin-bottom: 0;
}

.center {
  display: block;
  margin: 0 auto 30px;
}

.desktop {
  display: none;
}

.gform_wrapper .ginput_complex label {
  display: none !important;
}

.gform_wrapper .top_label .gfield_label {
  color: #3c4f55 !important;
  font-size: 1rem;
  font-weight: normal !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.gform_wrapper .gform_footer input[type=submit] {
  background-color: #ffffff;
  border: 1px solid #128ebc;
  color: #128ebc;
  padding: 10px 30px;
}

.gform_wrapper .gform_footer input[type=submit]:hover {
  background-color: #128ebc;
  color: #ffffff;
}

#gform_wrapper_1 {
  margin-top: 0 !important;
}

h1, h2, h3, h4, h5, h6, p.textlarge {
  color: #3c4f55;
  font-family: "source-sans-pro", helvetica, arial, sans-serif;
  text-transform: uppercase;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
}

h1, p.textlarge {
  font-size: 2rem;
  letter-spacing: 0.15rem;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.single-post h1 {
  font-size: 1.5rem;
  max-width: 750px;
  line-height: 1.75rem;
}

h2, .panel h2 {
  font-size: 1.5rem;
}

.locations h2 {
  font-size: 1.1875rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

h2.arrow {
  font-size: 1.25rem;
}

h2.arrow:hover {
  cursor: pointer;
}

h2.arrow.toggle::after {
  float: right;
  content: url(images/icons/arrow.png);
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: 0.3s;
}

h2.arrow a::after {
  float: right;
  content: url(images/icons/arrow.png);
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: 0.3s;
}

h2.arrow.arrowdown::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transition: 0.3s;
}

h2.regionlightblue,
h2.regionseafoam {
  padding: 20px 0;
  margin: 40px 0;
}

.blog h2 {
  line-height: 1.5rem;
}

h3, .panel h3 {
  color: #3c4f55;
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

h4 {
  font-size: 1rem;
}

.staffcontent h4 {
  font-size: 0.8125rem;
}

.helpcontainer {
  padding: 50px 0;
  margin-bottom: 40px;
}

.helpcontainer h2 {
  line-height: 1.15em;
  margin-bottom: 30px;
  text-align: center;
}

img.playbutton {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  left: 50%;
  margin-left: -21px;
}

a.open-popup-link {
  position: relative;
  display: block;
}
a.open-popup-link.video-shortcode {
  display: block;
  margin: 20px 0 0;
  max-width: 320px;
}

.video-caption {
  font-size: 12px;
}

iframe {
  max-width: 100%;
  padding: 0 15px;
}

.single-condition iframe {
  display: block;
  margin: 0 auto;
}

.testimonials .large-7 {
  margin: 0 auto;
  max-width: 100%;
  width: 560px;
}

.tabs-content iframe {
  margin-top: 30px;
}

.large-12 {
  padding-left: 1.09375rem;
  padding-right: 1.09375rem;
}

p, ul li, ul li a {
  color: #727272;
  font-size: 1em;
  line-height: 1.5em;
}

.row p {
  word-wrap: break-word;
}

nav .row p {
  word-wrap: none;
}

.faq section p, .covid-19-faq section p {
  padding-top: 3px;
  margin-left: 30px;
}

.regionwhite {
  background: #ffffff;
}

.halfcontainer .medium-10.regionwhite {
  margin-bottom: 30px;
}

.regionseafoam {
  background: #9acfb0;
}

.regionblue {
  background: #3c4f55;
}

.regionbrightblue {
  background: #0e4e63;
}

.single-condition .row ul.regionblue {
  padding: 10px 15px;
}

.regionlightblue {
  background: #128ebc;
}

.regiongreyblue {
  background: #6f98a2;
}

.regiongreyblue.cta {
  padding: 60px 0;
}

.regiongreyblue.cta p {
  margin-bottom: 0;
  letter-spacing: 0.05em;
  font-size: 1.25rem;
}

.regiongrey {
  background: #eeeeee;
}

.regiongrey.disclaimer {
  padding: 30px 0 9px;
}

.regiongrey.disclaimer p {
  font-size: 0.9375rem;
  text-align: center;
}

.patient-resources .regiongrey {
  padding: 30px 20px;
}

.patient-resources .regiongrey ul li,
.patient-resources .regiongrey ul li a,
.single-condition ul li,
.single-condition ul li a,
.conditions-we-treat ul li,
.conditions-we-treat ul li a,
.faq ul li,
.covid-19-faq ul li,
.faq ul li a,
.covid-19-faq ul li a {
  color: #727272;
}

section.resoucescontent {
  border: 2px solid #9acfb0;
  border-radius: 10px;
  margin: 15px 0;
  padding-bottom: 10px;
}

section.resoucescontent h4 {
  background: #9acfb0;
  border-radius: 6px 6px 0 0;
  color: #ffffff;
  margin: 0 0 15px;
  padding: 5px 20px;
}

section.resoucescontent p {
  font-size: 0.875em;
  padding: 0 20px;
  margin-bottom: 5px;
}

.faq .row section, .covid-19-faq .row section {
  margin-bottom: 40px;
}

.single-condition strong {
  color: #3c4f55;
}

.patient-resources .regiongrey p {
  margin-bottom: 10px;
}

.patient-resources .regiongrey h3 {
  margin-bottom: 10px;
  margin-top: 35px;
}

.single-procedure .regiongreyblue,
.single-condition .regiongreyblue {
  padding: 30px 0;
}

.regiongreyblue p {
  color: #ffffff;
}

.relative {
  position: relative;
}

.row.altgreysection {
  padding-top: 20px;
  padding-bottom: 60px;
}

.row.repeatingsections h2:first-child,
.row.introsection h2:first-child {
  margin-top: 0;
}

.row.repeatingsections {
  padding-top: 0;
  padding-bottom: 60px;
}

.row.introsection {
  padding-bottom: 0;
  padding-top: 0;
}

.row.sectionnavigation {
  padding: 0;
}

.faq .bannertitle, .covid-19-faq .bannertitle {
  margin-bottom: 20px;
}

.row.faq-section, .row.covid-19-faq-section {
  padding: 0;
}

.row.faq-section .column, .row.covid-19-faq-section .column {
  padding: 20px;
}

.request-appointment > .row,
.testimonials > .row,
.contact-us > .row {
  padding-bottom: 60px;
}

.patient-resources section {
  padding-top: 15px;
  padding-bottom: 5px;
}

.patient-resources section img {
  margin-bottom: 30px;
}

.patient-resources section:first-child {
  padding-top: 0;
}

.searchform .button {
  padding: 10px 15px 9px;
  width: 30%;
}

.searchform {
  max-width: 500px;
  margin: 40px auto 20px;
  padding: 0 20px;
}

.searchform .field {
  width: 70%;
  float: left;
}

.searchcontent article {
  border-bottom: 1px solid #b4b4b4;
  margin-bottom: 20px;
}

.searchcontent .medium-5 h2 {
  margin-bottom: 30px;
  font-size: 2em;
  text-transform: capitalize;
}

.searchcontent .medium-7 h2 {
  line-height: 1.5rem;
}

.textcenter {
  text-align: center;
}

.staffcontainer .large-12 .textcenter {
  padding: 30px 20px 0;
}

.textbold {
  font-weight: 600;
}

.textgreen {
  color: #368f71;
}

.textwhite,
p.textlarge.textwhite,
.textwhite p,
.sectionnavigation ul li,
.sectionnavigation ul li a,
.textwhite ul li,
.textwhite ul li a,
.textwhite h4,
.textwhite a {
  color: #ffffff;
}

.textwhite a:hover,
a.textwhite:hover {
  color: #e3e3e3;
}

.textgreyblue {
  color: #6f98a2;
}

.textdarkblue,
.textdarkblue p {
  color: #3c4f55;
}

.textblue {
  color: #128ebc;
}

.texttestimonials {
  font-size: 1.25rem;
  letter-spacing: 0.025em;
}

.textuppercase {
  text-transform: uppercase;
}

.textyellow,
.textyellow p,
.textyellow li {
  color: #f9f6e0;
}

.text24,
p.text24,
.top-bar-section .dropdown p.text24 {
  font-size: 1.5rem;
  line-height: 1em;
}

.top-bar-section .dropdown p.text22 {
  font-size: 1.375rem;
}

.text16 {
  font-size: 1rem;
}

.text20 {
  font-size: 1.25rem;
}

.text30 {
  font-size: 1.875rem;
}

ul.arrow li {
  background: url(images/icons/arrow.png) no-repeat right 7px;
  border-bottom: 1px solid #c8c8c8;
  font-size: 1.25rem;
  margin-top: 8px;
  padding: 0 30px 8px 0;
  text-transform: uppercase;
}

ul.arrow li a {
  color: #3c4f55;
}

ul.tabs.vertical {
  margin: 0;
  background: #eeeeee;
  padding: 10px 15px 20px;
}

ul.tabs.vertical li {
  list-style: none;
  margin: 0;
  border-bottom: 1px solid #bcc9ce;
  padding: 7px 0;
}

ul.tabs.vertical li:last-child {
  border: none;
}

ul.tabs.vertical li a {
  color: #3c4f55;
}

ul.tabs.vertical li.active a {
  color: #128ebc;
}

ul.simple {
  margin-left: 0;
}

ul.simple li {
  list-style: none;
}

ul.inline {
  list-style: none;
  margin: 0 auto;
  text-align: center;
}

ul.inline li {
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 15px;
  text-transform: uppercase;
}

ul.regionlightblue li {
  border-bottom: 1px solid #ffffff;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  text-transform: uppercase;
}

ul.regionlightblue li a {
  display: block;
  padding: 15px 0 15px 75px;
}

ul.regionlightblue li a.phoneicon {
  background: url(images/icons/contact-white.png) no-repeat 25px center;
}

ul.regionlightblue li a.calendaricon {
  background: url(images/icons/schedule-white.png) no-repeat 22px center;
}

ul.regionlightblue li a.handsicon {
  background: url(images/icons/surgeons-white.png) no-repeat 17px center;
}

ul.regionlightblue li a.locationicon {
  background: url(images/icons/location-white.png) no-repeat 25px center;
}

li.arrow,
p.arrow {
  background: url(images/icons/arrow.png) no-repeat 0 5px;
  list-style: none;
  margin-bottom: 9px;
  padding-left: 16px;
}

.conditions-we-treat li.arrow a,
p.arrow a,
.conditions-we-treat .bulletlist li,
.conditions-we-treat .bulletlist li a {
  color: #128ebc;
}

/************ Header ************/
.bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
  width: 100%;
}

.bx-wrapper .bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.bx-controls .bx-pager {
  margin-top: 10px;
  text-align: center;
}

.bx-controls .bx-pager .bx-pager-item {
  display: inline-block;
  margin: 0 0 0 10px;
}

.bx-controls .bx-pager .bx-pager-item:first-child {
  margin: 0;
}

.bx-controls .bx-pager .bx-pager-item a {
  border-radius: 100%;
  border: 1px solid #1b1e19;
  display: block;
  height: 20px;
  text-align: left;
  text-indent: -999em;
  width: 20px;
}

.bx-controls .bx-pager .bx-pager-item a:hover {
  background: #3A4E54;
}

.bx-controls .bx-pager .bx-pager-item a.active {
  background: #1b1e19;
}

.bx-wrapper .bx-prev {
  left: -20px;
  background: url(images/icons/arrow-prev.png) no-repeat center top;
}

.bx-wrapper .bx-next {
  right: -20px;
  background: url(images/icons/arrow-next.png) no-repeat center top;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  margin-top: 0;
  outline: 0;
  width: 15px;
  height: 31px;
  text-indent: -9999px;
  z-index: 9999;
}

header {
  font-family: "source-sans-pro", helvetica, arial, sans-serif;
  margin: 0 auto;
  padding: 0;
}

.slide .column h2 {
  font-size: 2.125rem;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 0.2em 0;
}

.slide .column p {
  font-size: 0.875rem;
}

.slide .column p.subtitle {
  font-size: 0.875rem;
  line-height: 1;
  margin: 0;
}

.slide .column p:last-child,
.slide .column a.button {
  margin: 0;
}

.slide img {
  margin: 0 auto 20px;
}

.slideshowcontainer h2 {
  margin-bottom: 40px;
  text-transform: none;
}

.testimonialcontent p {
  font-size: 1.1875rem;
}

header .top-bar-section ul.social {
  display: none;
  margin: 0 0 0 2px;
}

header .top-bar-section ul.social li {
  display: inline-block;
  margin-right: 2px;
  text-indent: -999em;
}

header .top-bar-section ul.social li a {
  display: block;
}

header .top-bar-section ul.social li a.twitter,
header .top-bar-section ul.social li a.twitter:hover {
  background: url(images/icons/twitter-square.png) no-repeat center center;
  height: 25px;
  width: 25px;
}

header .top-bar-section ul.social li a.facebook,
header .top-bar-section ul.social li a.facebook:hover {
  background: url(images/icons/facebook-square.png) no-repeat center center;
  height: 25px;
  width: 25px;
}

header .top-bar-section ul.social li a.linkedin,
header .top-bar-section ul.social li a.linkedin:hover {
  background: url(images/icons/linkedin-grey.png) no-repeat center center;
  height: 11px;
  width: 12px;
}

header .top-bar-section ul.social li a.google-plus,
header .top-bar-section ul.social li a.google-plus:hover {
  background: url(images/icons/google-grey.png) no-repeat center center;
  height: 10px;
  width: 12px;
}

/************ Content ************/
.bannertitle {
  background: #144f63;
  padding: 15px;
}

.tribe-events-page-template .bannertitle {
  height: 70px;
}

.halfcontainer {
  padding: 1.5rem 0;
}

.halfcontainer .medium-6 {
  width: 100%;
}

.home-banner {
  background-color: #EBF6F0;
}

.home-banner .row {
  padding-bottom: 20px;
  padding-top: 20px;
}

.home-banner .row .column {
  margin-bottom: 20px;
}

.home-banner .row .column:last-child {
  margin-bottom: 0;
}

.home-banner .row .column a.button {
  margin-bottom: 0;
}

.home-banner .row .column h1 {
  color: #9acfb0;
  font-size: 1.75em;
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 15px;
  text-transform: none;
}

.home-banner .row .column h2 {
  color: #3c4f55;
  text-transform: none;
}

.home-banner .row .column h2 span {
  color: #9acfb0;
}

.home-banner .row .column p {
  color: #3c4f55;
  font-size: 18px;
}

.home-banner .row .column p:last-child {
  margin-bottom: 0;
}

.home-banner .row .column .open-popup-link {
  position: relative;
}
.home-banner .row .column .open-popup-link::after {
  content: "";
  display: block;
  color: #a2d0ad;
  padding-top: 20px;
  text-align: center;
  font-family: "source-sans-pro", helvetica, arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  background: rgb(29, 54, 66);
  background: linear-gradient(90deg, rgb(29, 54, 66) 0%, rgb(41, 74, 91) 50%, rgb(29, 54, 66) 100%);
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .home-banner .row .column .open-popup-link::after {
    position: relative;
  }
}
.home-banner .row .column .open-popup-link::before {
  content: url("./images/play-arrow2.png");
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  bottom: 80px;
  left: 35%;
  z-index: 999;
}
.home-banner .row .column .open-popup-link img {
  position: relative;
}

body > .row {
  padding: 20px 0;
}

.row.narrow {
  max-width: 51.5em;
}

.mapcontainer {
  margin-bottom: 40px;
}

.mapcontainer h3 {
  margin-bottom: 0;
}

.mapcontainer ul li {
  line-height: 1.45;
}

.mapcontainer ul li a {
  color: #727272;
  font-size: 0.875em;
}

.mapcontainer .map {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.row-columns .column-content {
  border-top: 4px solid #ffffff;
  padding: 18px 25px;
}

.row-columns .column {
  margin-bottom: 20px;
}

.row-columns .column:last-child {
  margin-bottom: 0;
}

.row-columns .column-content p:last-child {
  margin-bottom: 0;
}

.conditionlist .medium-4 {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 8px;
  padding-bottom: 0;
  padding-right: 0;
}

.row.conditionlist {
  padding: 0 15px 60px;
}

.row.testimonials {
  padding: 40px 15px 30px;
}

.single-condition .row.staffcontainer h2.regionseafoam {
  margin-top: 0;
}

.single-condition .row.staffcontainer {
  padding-top: 0;
}

.schedulecontainer {
  padding: 20px 0 0;
}

.single-staff_member h1 {
  font-size: 1.4375rem;
  font-weight: bold;
  text-transform: none;
  letter-spacing: normal;
  margin-bottom: 0;
}

.single-staff_member h2 {
  color: #24586b;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 10px;
}

.single-staff_member h3,
.single-staff_member h4 {
  color: #24586b;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.stafftitle p {
  font-size: 1.4375rem;
}

.single-staff_member .staffbio .medium-8 ul {
  margin-left: 0;
  margin-bottom: 30px;
}

.single-staff_member .staffbio .medium-8 ul li {
  color: #727272;
  font-size: 1rem;
  list-style: none;
  margin-bottom: 5px;
}

.single-staff_member .staffbio .medium-4 ul {
  margin-top: 10px;
}

.single-staff_member .staffbio .medium-4 ul li {
  color: #727272;
  font-size: 1rem;
  line-height: 16px;
  margin-bottom: 4px;
}

.single-staff_member > .staffbio > .medium-8 > p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.stafftitle {
  padding: 40px 10px;
}

.single-staff_member .staffbio .medium-4 img.featured {
  display: block;
  margin-bottom: 25px;
  width: 100%;
}

.single-staff_member .row.staffbio .left-column a {
  color: #128ebc;
  text-decoration: underline;
}

.single-staff_member .row.staffbio .left-column a.button {
  color: #ffffff;
  display: block;
  font-size: 1.2rem;
  padding: 0.7rem 1rem 0.6rem;
  text-decoration: none;
}

.surgeons-staff h2 {
  margin-bottom: 40px;
}

.staffcontainer .medium-2-5 img,
.staffcontainer .medium-4 img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.staffcontent {
  padding: 10px 0;
  margin: 0 auto 5px;
  max-width: 258px;
}

/*.single-procedure .staffcontent {
    margin-bottom: 25px;
}*/
.staffcontent.regiondarkblue {
  align-items: center;
  background: #144f63;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 10px 15px;
  position: relative;
  margin-top: -58px;
  min-height: 59px;
}

.staffcontent p, .staffcontent h4 {
  margin-bottom: 0;
  margin-top: 0;
}

.hiddenstaffcontent {
  display: none;
}

.hiddenstaffcontent p, .hiddenstaffcontent ul li,
.hiddenstaffcontent ul li a, .hiddenstaffcontent a.button {
  font-size: 0.75rem;
}

.hiddenstaffcontent h4 {
  font-size: 0.75rem;
  margin-bottom: 0;
}

.hiddenstaffcontent a {
  color: inherit;
  text-decoration: underline;
}

.hiddenstaffcontent a.button {
  text-decoration: none;
}

.tabs-content {
  margin-bottom: 0;
}

.testimonials .slideshowcontainer {
  padding: 50px 10px 40px;
}

.testimonials .bx-controls .bx-pager .bx-pager-item a.active,
.testimonials .bx-controls .bx-pager .bx-pager-item a:hover {
  background: #aaaaaa;
}

.testimonials .bx-controls .bx-pager .bx-pager-item a {
  border: 1px solid #aaaaaa;
}

ul.medium-4 {
  padding-left: 0;
}

/************ Footer ************/
footer {
  margin: 0 auto;
  max-width: 75em;
  padding: 30px;
  width: 100%;
}

.footertopnav,
.footersecondarynav {
  display: none;
}

img.footerlogo {
  display: block;
  margin: 0 auto 30px;
}

footer ul.social {
  margin: 0 auto;
  text-align: center;
}

footer ul.social li {
  display: inline-block;
  margin-right: 25px;
}

footer ul.social li a {
  display: block;
}

footer ul.social li a.twitter {
  background: url(images/icons/twitter.png) no-repeat center center;
  height: 26px;
  width: 33px;
}

footer ul.social li a.facebook {
  background: url(images/icons/facebook.png) no-repeat center center;
  height: 32px;
  width: 16px;
}

footer ul.social li a.linkedin {
  background: url(images/icons/linkedin.png) no-repeat center center;
  height: 31px;
  width: 33px;
}

footer ul.social li a.google-plus {
  background: url(images/icons/google-plus.png) no-repeat center center;
  height: 28px;
  width: 33px;
}

/************ Events Calendar Styles ************/
#tribe-bar-form .tribe-bar-submit input[type=submit] {
  background: #144f63 !important;
}

.tribe-events-calendar thead th {
  background-color: #144f63 !important;
  border-left: 1px solid #103f4f !important;
  border-right: 1px solid #103f4f !important;
}

#tribe-events .tribe-events-button,
#tribe-events .tribe-events-button:hover,
#tribe_events_filters_wrapper input[type=submit],
.tribe-events-button, .tribe-events-button.tribe-active:hover,
.tribe-events-button.tribe-inactive, .tribe-events-button:hover,
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-],
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-] > a {
  background-color: #144f63 !important;
}

.single-tribe_events .tribe-events-event-meta {
  background: #f1f1f1 !important;
}

.tribe-event-description p {
  color: #6a6a6a;
  font-size: 13px !important;
}

abbr.tribe-events-abbr.tribe-event-date-start {
  font-weight: bold !important;
  font-size: 14px !important;
}

/************ Foundation Overrides ************/
ul.no-bullet {
  margin-bottom: 0;
}

/*** TOP BAR ***/
.top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button),
.contain-to-grid,
.top-bar,
.top-bar-section ul li,
.top-bar-section li:not(.has-form) > a:not(.button),
.top-bar.expanded .title-area {
  background: #98D0AB;
}

.top-bar,
.top-bar .name {
  height: 75px;
}

.top-bar .name a {
  display: inline-block;
  height: 50px;
  margin-left: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.top-bar .name a img {
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.top-bar .toggle-topbar {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.top-bar .toggle-topbar a {
  color: #3c4f55;
  display: block;
  font-size: 2rem;
  font-weight: 200;
  height: 2.8125rem;
  line-height: 2.8125rem;
  padding: 0 0.9375rem;
  text-transform: lowercase;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.top-bar.expanded .toggle-topbar a {
  color: #ffffff;
}

.top-bar-section ul li {
  border-top: 1px solid #B6D8C2;
  border-bottom: 1px solid #8DC19E;
}

.top-bar-section ul li.moved .dropdown li {
  padding: 5px 15px;
}

.dropdown p.text24 {
  padding-top: 10px;
}

.top-bar-section ul li.subitem > a {
  color: #ffffff;
}

.top-bar-section ul li > a {
  color: #3c4f55;
  font-family: "source-sans-pro";
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.top-bar-section ul li:hover:not(.has-form) > a {
  background: transparent;
}

.top-bar-section .dropdown li.title h5 a,
.top-bar-section .dropdown li.parent-link > a {
  color: #3c4f55;
  font-weight: bold;
}

ul.title-area {
  border-bottom: 1px solid #8DC19E;
}

ul.target,
div.target {
  display: none;
  margin-bottom: 10px;
}

div.target {
  padding-left: 1.1rem;
}

/* ----------- START Back to Top Styles ----------- */
.cd-container {
  width: 90%;
  max-width: 768px;
  margin: 2em auto;
}

.cd-container::after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-top {
  display: inline-block;
  height: 32px;
  width: 32px;
  position: fixed;
  bottom: 60px;
  right: 30px;
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: url(images/icons/arrow-back-to-top.png) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}

.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: 0.5;
}

.no-touch .cd-top:hover {
  background-color: #e86256;
  opacity: 1;
}

/* -----------  END Back to Top Styles ----------- */
/************************************ Media Queries ************************************/
@media only screen and (min-width: 760px) {
  /************ Universal Styles ************/
  a.button.tall {
    display: block;
    height: 55px;
    padding: 0.4rem 0 0.3rem;
  }
  a.button.alignleft {
    margin: 15px 15px 15px 0;
  }
  .alignbottom {
    bottom: 0;
    position: absolute;
  }
  .alignvert {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
  .row.aligncenter {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
  .alignleft {
    float: left;
    margin: 0 35px 15px 0;
    clear: none;
  }
  .alignright {
    float: right;
    margin: 0 0 15px 35px;
    clear: none;
  }
  .bottomcontainer {
    padding-bottom: 50px;
  }
  .bx-wrapper .bx-controls-direction a {
    display: block;
  }
  .desktop {
    display: block;
  }
  article h2,
  .patient-resources section h2 {
    line-height: 1rem;
  }
  .helpcontainer {
    margin-bottom: 0;
  }
  .helpcontainer h2 {
    text-align: left;
    margin-bottom: 0;
  }
  .medium-8 h2.regionlightblue:first-child,
  .single-procedure .large-12:first-child h2.regionlightblue,
  .aside.regiongrey,
  .single-procedure .staffcontainer h2.regionlightblue {
    margin-top: 0;
  }
  .medium-2-5 {
    width: 19.2%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 5px;
  }
  .medium-2-5.column + .medium-2-5.column:last-child,
  .medium-2-5.columns + .medium-2-5.column:last-child,
  .medium-2-5.column + .medium-2-5.columns:last-child,
  .medium-2-5.columns + .medium-2-5.columns:last-child,
  .medium-3.column + .column:last-child {
    float: left;
  }
  .videos .medium-4 {
    padding: 0;
    margin-left: 2%;
    width: 32%;
  }
  .videos .medium-4:last-child {
    float: left;
  }
  .videos .medium-4.column:nth-child(3n+1) {
    margin-left: 0;
  }
  .conditionlist .medium-4 {
    margin-left: 5%;
    padding: 0;
    width: 30%;
  }
  .conditionlist .medium-4:nth-child(3n+1) {
    clear: both;
    margin-left: 0;
  }
  .conditionlist .medium-4:last-child {
    float: left;
  }
  .regionwhite {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .searchform {
    margin: 80px auto 70px;
  }
  .searchcontent .medium-7.column {
    padding-right: 70px;
  }
  .searchcontent .medium-5.column.border {
    border-left: 1px solid #b4b4b4;
    padding-left: 50px;
    padding-top: 20px;
    margin-top: 30px;
  }
  .text-intro {
    font-size: 20px;
  }
  ul.col2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px; /* Firefox */
    column-gap: 40px;
  }
  ul.col3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px; /* Firefox */
    column-gap: 40px;
  }
  /************ Header ************/
  .bx-controls {
    bottom: 0;
    width: 100%;
  }
  .bx-controls .bx-pager {
    margin: 0 auto;
    max-width: 61.25rem;
    text-align: right;
  }
  .testimonials .bx-controls .bx-pager {
    text-align: center;
  }
  .bx-controls .bx-pager .bx-pager-item {
    display: inline-block;
    margin: 0 0 0 7px;
  }
  .bx-controls .bx-pager .bx-pager-item:first-child {
    margin: 0;
  }
  .bx-controls .bx-pager .bx-pager-item a {
    height: 15px;
    width: 15px;
  }
  .testimonials .slideshowcontainer {
    max-width: 760px;
    margin: 0 auto;
  }
  .slideshowcontainer h2 {
    margin-bottom: 60px;
    padding: 10px 0;
  }
  header {
    padding: 0 20px;
  }
  header .headercontent {
    clear: right;
    padding: 33px 7px 10px;
  }
  header p.headertext {
    color: #98a4a4;
    float: right;
    font-size: 1.125em;
    letter-spacing: 0.05em;
    line-height: 1;
    margin: 0;
    text-align: right;
    text-transform: uppercase;
  }
  header p.headertext a {
    color: #98a4a4;
    font-size: 1.5em;
  }
  header p.headertitle {
    color: #3c4f55;
    float: left;
    font-family: "garamond-premier-pro-caption", garamond, "Times New Roman", times, serif;
    font-size: 2.25em;
    line-height: 1;
    margin: 15px 0 0;
  }
  .top-bar-section ul li.subitem > a {
    color: #ffffff;
  }
  .top-bar-section ul li.moved .dropdown li {
    padding: 5px 0;
  }
  .dropdown p.text24 {
    padding-top: 0;
  }
  .slide .column {
    max-width: 375px;
  }
  .slide img {
    margin: 0;
  }
  .tabs-content {
    margin-bottom: 4rem;
  }
  .tabs-content {
    float: right;
    width: 60%;
    margin-left: 2%;
  }
  .tabs-content > .content {
    padding-top: 0;
  }
  ul.tabs.vertical {
    margin: 0 0 0 15px;
    width: 36%;
    max-width: 100%;
  }
  /************ Content ************/
  .bannertitle {
    padding: 0;
    position: relative;
    min-height: 235px;
  }
  .tribe-events-page-template .bannertitle {
    height: 216px;
  }
  .bannertitle img {
    float: right;
    height: 100%;
    width: auto;
  }
  .bannercontent {
    position: absolute;
    top: 45px;
    left: 15px;
  }
  .home-banner .row {
    -ms-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
  }
  .home-banner .row:before,
  .home-banner .row:after {
    display: none;
  }
  .home-banner .row .column {
    margin-bottom: 0;
  }
  .home-banner .row .column:first-child {
    order: 2;
  }
  .home-banner .row .column:last-child {
    order: 1;
    padding-right: 60px;
  }
  .home-banner .row .column img {
    display: block;
    margin: 0 0 0 auto;
    width: auto;
  }
  .row-columns .column {
    margin-bottom: 0px;
  }
  body > .row {
    padding: 60px 0 0;
  }
  body > .row.pt0 {
    padding-top: 0;
  }
  body > #tribe-events-pg-template.row {
    padding-bottom: 60px;
  }
  .single-post > .row {
    padding-bottom: 60px;
  }
  .row.conditionlist {
    padding-top: 40px;
  }
  .single-staff_member .row.staffbio {
    padding-bottom: 30px;
  }
  .row.staffcontainer {
    padding-bottom: 50px;
  }
  .staffcontainer .childcontainer {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .row.staffcontainer .childcontainer.small {
    max-width: 67%;
    margin: 0 auto;
  }
  .row.staffcontainer .childcontainer.small .medium-2-5 {
    margin-left: 5px;
    width: 29.2%;
  }
  .row.staffcontainer .childcontainer.small .medium-2-5:first-child,
  .row.staffcontainer .childcontainer.small .medium-2-5:nth-child(3n+1) {
    margin-left: 0;
  }
  .single-staff_member .staffbio .medium-4 img.featured {
    position: relative;
    margin-top: -130px;
  }
  body > .row.pb60 {
    padding: 60px 0;
  }
  .faq .bannertitle, .covid-19-faq .bannertitle {
    margin-bottom: 30px;
  }
  .row.faq-section .column, .row.covid-19-faq-section .column {
    padding: 30px;
  }
  .halfcontainer {
    float: left;
    padding: 1.5rem 0px;
    width: 50%;
  }
  .mapcontainer {
    margin-bottom: 0;
    min-height: 360px;
  }
  p.indent {
    padding: 0 60px;
  }
  .patient-resources .regiongrey {
    padding: 60px 20px;
  }
  .row.paddingb60,
  .paddingb60 {
    padding-bottom: 60px;
  }
  .row.sectionnavigation {
    position: relative;
    margin-top: -25px;
  }
  .staffcontainer .medium-2-5:hover .hiddenstaffcontent {
    display: block;
  }
  .hiddenstaffcontent {
    margin: -5px 0 0 0;
    padding: 0 15px 0;
    position: absolute;
    width: 100%;
    z-index: 999;
  }
  .hiddenstaffcontent p a {
    text-decoration: underline;
  }
  .staffcontainer .medium-2-5:hover .staffcontent.regiondarkblue {
    background: #128ebc;
  }
  .testimonials .slideshowcontainer {
    padding: 60px 10px 80px;
  }
  .testimonials .bx-controls {
    bottom: -40px;
  }
  .testimonialcontent {
    max-width: 700px;
    margin: 0 auto;
  }
  /************ Footer ************/
  footer .column:first-child {
    padding-left: 0;
  }
  .footertopnav,
  .footersecondarynav {
    display: block;
  }
  .footersecondarynav {
    padding-right: 0;
  }
  .footertopnav {
    background: #3c4f55;
    padding: 15px 0;
  }
  .footertopnav ul,
  .footersecondarynav ul {
    margin: 0 auto;
    text-align: center;
  }
  .footertopnav ul li,
  .footersecondarynav ul li {
    display: inline-block;
    font-size: 0.875rem;
    margin-left: 25px;
  }
  .footersecondarynav ul li {
    font-size: 0.6875rem;
    margin-left: 15px;
  }
  .footertopnav ul li:first-child,
  .footersecondarynav ul li:first-child {
    margin-left: 0;
  }
  .footertopnav ul li a {
    color: #ffffff;
  }
  .footersecondarynav ul li a {
    color: #3c4f55;
  }
  /************ Foundation Overrides ************/
  /*** TOP BAR ***/
  .top-bar,
  .top-bar .name {
    height: auto;
  }
  .top-bar .name a {
    height: auto;
    margin: 0;
    position: static;
    top: auto;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
  }
  .top-bar .title-area {
    position: absolute;
    top: 10px;
    width: 19%;
    z-index: 100;
  }
  .top-bar-section {
    float: right;
    margin-left: 0;
    z-index: 50;
    width: 80%;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button),
  .contain-to-grid,
  .top-bar,
  .top-bar-section ul li,
  .top-bar-section li:not(.has-form) > a:not(.button) {
    background: transparent;
  }
  .top-bar-section > ul.right {
    position: relative;
  }
  .top-bar-section > ul.right.mainnav {
    display: block;
    width: 100%;
  }
  .top-bar-section ul li {
    border: none;
    margin-bottom: 0;
    position: static !important;
  }
  .top-bar-section li.button-nav.first {
    margin-left: 10px;
  }
  .top-bar-section li:not(.has-form) > a:not(.button) {
    color: #98a4a4;
    font-size: 0.75rem;
    line-height: 1;
    padding: 6px !important;
  }
  .top-bar-section li.button-nav:not(.has-form) > a {
    background: #128ebc;
    color: #ffffff;
    margin-left: 2px !important;
    text-align: center;
    width: 132px;
  }
  .top-bar-section li.search.button-nav:not(.has-form) > a {
    width: auto;
    padding: 4px 12px 6px !important;
  }
  .top-bar-section > ul > li.has-dropdown:not(.has-form) > a:not(.button) {
    border: 1px solid transparent;
    color: #3c4f55;
    font-weight: bold;
    padding: 10px 6px !important;
    position: relative;
    z-index: 100;
  }
  .top-bar-section > ul > li:not(.has-form) > a:not(.button):hover {
    background: transparent;
    color: #6f98a2;
  }
  .top-bar-section > ul > li.button-nav:not(.has-form) > a:hover {
    background: #4bb2d8;
    color: #ffffff;
  }
  .top-bar-section > ul > li.has-dropdown:not(.has-form) > a:not(.button):hover {
    background: #eeeeee;
    border: 1px solid #6f98a2;
    border-bottom: none;
    color: #6f98a2;
  }
  .top-bar-section > ul > li.has-dropdown:hover:not(.has-form) > a {
    background-color: #eeeeee;
    color: #6f98a2;
    background: #eeeeee;
    border: 1px solid #6f98a2;
    border-bottom: none;
  }
  .top-bar-section .has-dropdown > a:after {
    display: none;
  }
  .top-bar-section .dropdown {
    background: #eeeeee;
    border: 1px solid #6f98a2;
    margin-top: -1px;
    padding: 35px;
    z-index: 50;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
    color: #6f98a2;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
    background: transparent;
    color: #3c4f55;
  }
  .top-bar-section .dropdown .row .column {
    border-left: 1px solid #D9E0E1;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    left: -1px;
  }
  .top-bar-section .dropdown .row .column:first-child {
    border-right: 1px solid #D9E0E1;
    border-left: none;
    padding-left: 1rem;
    padding-right: 1rem;
    left: 0;
  }
  .top-bar-section .dropdown img {
    display: inline-block !important;
    margin: 0 15px 15px 0;
    vertical-align: middle;
  }
  .top-bar-section .dropdown .column:first-child p {
    display: inline-block;
    max-width: 172px;
    margin: 0 0 15px 0;
    vertical-align: middle;
  }
  .top-bar-section .dropdown p {
    color: #6f98a2;
    font-size: 1em;
  }
  .top-bar-section .dropdown ul.sublist {
    float: left;
    width: 50%;
  }
  .top-bar-section .dropdown ul.sublist li a {
    white-space: normal;
  }
  .top-bar-section .search.desktop {
    display: inline-block;
  }
  ul.title-area {
    border-bottom: none;
  }
}
@media only screen and (min-width: 960px) {
  /************ Header ************/
  header .top-bar-section ul.social {
    display: inline-block;
  }
  /************ Footer ************/
  .footersecondarynav ul li,
  .footertopnav ul li {
    letter-spacing: 0.05em;
  }
  /************ Content ************/
  a.button.alignleft {
    margin-right: 32px;
    width: 212px;
  }
  .helpcontainer a.button.alignleft:last-child {
    margin-right: 0;
  }
  .altgreysection article,
  .altgreysection article:nth-child(2n+1) {
    padding-right: 148px;
  }
  .bannercontent {
    top: 95px;
    left: auto;
  }
  .locations .bannercontent,
  .single-location .bannercontent {
    top: 75px;
  }
  .halfcontainer .medium-6 {
    width: 65%;
  }
  .halfcontainer:first-child .medium-6 {
    padding-right: 60px;
  }
  .halfcontainer:nth-child(2) {
    padding-left: 60px;
  }
  .home-banner .row {
    padding-bottom: 40px;
    padding-top: 55px;
  }
  .medium-2-5 {
    width: 19.55%;
  }
  .medium-2-5:first-child,
  .medium-2-5:nth-child(5n+1) {
    clear: both;
    margin-left: 0;
  }
  p.indent {
    padding: 0 160px;
  }
  .patient-resources .large-12,
  .patient-resources .medium-9.column,
  .patient-resources .medium-8.column {
    padding-left: 0;
  }
  .faq .bannertitle, .covid-19-faq .bannertitle {
    margin-bottom: 40px;
  }
  .row.faq-section .column, .row.covid-19-faq-section .column {
    padding: 40px;
  }
  .single-procedure .regiongreyblue,
  .single-condition .regiongreyblue {
    padding: 70px 0 40px;
  }
  .single-condition .regiongreyblue {
    padding-bottom: 50px;
  }
  .schedulecontainer h3.textwhite {
    margin-left: 100px;
  }
  .single-staff_member .medium-9.column {
    padding-left: 50px;
  }
  ul.tabs.vertical {
    margin: 0;
  }
  ul.tabs.vertical {
    width: 38%;
  }
  .tabs-content.height {
    width: 60%;
  }
  .gform_wrapper input[type=checkbox] {
    margin: 0 10px 0 0;
  }
  .gform_wrapper li.half {
    clear: none !important;
    float: left;
    margin-left: 8%;
    width: 46%;
  }
  .gform_wrapper li.first {
    clear: both;
    margin-left: 0;
  }
  .gform_wrapper .ginput_recaptcha iframe {
    padding: 0;
  }
}
/**/
.gform_wrapper .gfield_time_hour i {
  display: none;
}

.ginput_container_radio ul {
  max-width: 200px !important;
}

.pencil-banner {
  /*background-color: $green-light;*/
  background-color: #9B2335;
  color: #ffffff;
  text-align: center;
  padding: 0.5rem 0;
  margin: 0 0 1rem 0;
}

.pencil-banner p {
  margin: 0;
  color: inherit;
}

.pencil-banner p a {
  color: inherit;
  font-weight: 600;
}

/**/
.staff-page-title .row {
  padding: 40px 0;
}

.staff-page-title .row .column p {
  margin-bottom: 0;
}

@media only screen and (min-width: 960px) {
  .staff-page-title .row .column {
    padding-left: 40px;
  }
}
.staffbio .left-column .videos-sidebar {
  border-bottom: 2px solid #c1c1c1;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.staffbio .left-column .videos-sidebar .single-video {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin-bottom: 15px;
}

.staffbio .left-column .videos-sidebar .single-video a {
  display: block;
  flex: 0 0 50%;
  margin-right: 10px;
}

@media only screen and (min-width: 960px) {
  .staffbio .left-column .videos-sidebar .single-video a {
    margin-right: 15px;
  }
}
.staffbio .left-column .videos-sidebar .single-video a img.video-image {
  display: block;
  width: 100%;
}

.staffbio .left-column .videos-sidebar .single-video p {
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (min-width: 960px) {
  .columns-2 {
    columns: 2;
    column-gap: 40px;
  }
}
ul.bullets {
  padding-left: 20px;
}

ul.bullets li {
  list-style: disc !important;
}

.icon-row-intro p:last-child {
  margin-bottom: 0;
}

.icon-row-intro {
  margin-bottom: 30px;
}

@media only screen and (min-width: 960px) {
  .icon-row-intro {
    margin-bottom: 50px;
  }
}
.icon-row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-row .column {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.icon-row .column .button {
  background-color: #164F63;
  color: #ffffff;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin-bottom: 0;
}

@media only screen and (min-width: 960px) {
  .icon-row {
    justify-content: space-between;
  }
  .icon-row .column {
    margin-bottom: 0;
    padding: 0;
  }
}
.steps-container {
  background-color: #EBF6F0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media only screen and (min-width: 760px) {
  .steps-container {
    margin-bottom: 40px;
    margin-top: 70px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 960px) {
  .steps-container {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1046px) {
  .steps-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.steps-container .steps-row {
  max-width: 1016px;
}
@media only screen and (min-width: 760px) {
  .steps-container .steps-row {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -30px;
  }
}
.steps-container .steps-row .steps-intro {
  background-color: #6f98a2;
  flex: 0 0 100%;
  padding: 20px;
}
@media only screen and (min-width: 760px) {
  .steps-container .steps-row .steps-intro {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .steps-intro {
    padding: 30px 45px;
  }
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .steps-intro .intro-title {
    flex: 0 0 29%;
    max-width: 29%;
  }
}
.steps-container .steps-row .steps-intro .intro-title h2 {
  color: #ffffff;
  font-size: 28px;
  line-height: 40px;
  margin: 0;
  text-transform: none;
}
@media only screen and (min-width: 760px) {
  .steps-container .steps-row .steps-intro .intro-content {
    flex: 0 0 64%;
    max-width: 64%;
    margin-left: 2%;
  }
}
.steps-container .steps-row .steps-intro .intro-content p, .steps-container .steps-row .steps-intro .intro-content li {
  font-size: 16px;
  line-height: 24px;
}
.steps-container .steps-row .steps-intro .intro-content p:last-child, .steps-container .steps-row .steps-intro .intro-content li:last-child {
  margin-bottom: 0;
}
.steps-container .steps-row .step-column {
  /*@media only screen and (min-width:760px) {
  	flex: 0 0 33.33%;
  	max-width: 33.33%;
  }*/
}
.steps-container .steps-row .step-column .step-title {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  position: relative;
}
@media only screen and (min-width: 760px) {
  .steps-container .steps-row .step-column .step-title {
    align-items: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    min-height: 68px;
    padding: 10px 40px 10px 20px;
  }
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .step-column .step-title {
    padding: 10px 40px 10px 45px;
  }
}
@media only screen and (min-width: 1046px) {
  .steps-container .steps-row .step-column .step-title {
    min-height: 0;
    padding: 10px 28px 10px 45px;
  }
}
@media only screen and (min-width: 760px) {
  .steps-container .steps-row .step-column .step-title:after {
    border-top: 34px solid transparent;
    border-bottom: 34px solid transparent;
    border-right: 34px solid rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 0;
    left: -34px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
}
@media only screen and (min-width: 1046px) {
  .steps-container .steps-row .step-column .step-title:after {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-right: 22px solid rgba(0, 0, 0, 0.5);
    left: -22px;
  }
}
.steps-container .steps-row .step-column .step-title h3 {
  color: #ffffff;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
.steps-container .steps-row .step-column .step-content {
  padding: 20px;
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .step-column .step-content {
    padding: 40px 34px;
  }
}
.steps-container .steps-row .step-column .step-content a.large {
  font-size: 26px;
  text-transform: uppercase;
}
.steps-container .steps-row .step-column .step-content .open-popup-link,
.steps-container .steps-row .step-column .step-content > img {
  display: block;
  margin-bottom: 20px;
}
.steps-container .steps-row .step-column .step-content .open-popup-link img:not(.playbutton),
.steps-container .steps-row .step-column .step-content > img img:not(.playbutton) {
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .step-column .step-content p, .steps-container .steps-row .step-column .step-content li {
    max-width: 82%;
  }
}
.steps-container .steps-row .step-column .step-content p:last-child, .steps-container .steps-row .step-column .step-content li:last-child {
  margin-bottom: 0;
}
.steps-container .steps-row .step-column .step-content p.with-video, .steps-container .steps-row .step-column .step-content li.with-video {
  max-width: 100%;
}
.steps-container .steps-row .step-column.step-1 {
  background-color: #158EBC;
}
.steps-container .steps-row .step-column.step-1 .step-title {
  background-color: #06759F;
}
.steps-container .steps-row .step-column.step-1 .step-title:after {
  display: none;
}
.steps-container .steps-row .step-column.step-1 .step-content {
  padding: 20px;
}
@media only screen and (min-width: 960px) {
  .steps-container .steps-row .step-column.step-1 .step-content {
    padding: 20px 25px;
  }
}
.steps-container .steps-row .step-column.step-1 .step-content p {
  color: #164F63;
}
.steps-container .steps-row .step-column.step-1 .step-content p a {
  color: #ffffff;
  display: block;
  text-decoration: underline;
}
.steps-container .steps-row .step-column.step-2 {
  background-color: #326577;
}
.steps-container .steps-row .step-column.step-2 .step-title {
  background-color: #164F63;
}
.steps-container .steps-row .step-column.step-2 .step-title:after {
  border-right-color: #164F63;
}
.steps-container .steps-row .step-column.step-3 {
  background-color: #516269;
}
.steps-container .steps-row .step-column.step-3 .step-title {
  background-color: #3D4E55;
}
.steps-container .steps-row .step-column.step-3 .step-title:after {
  border-right-color: #3D4E55;
}